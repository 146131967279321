<template>
  <div class="orStaffManagement">
    <el-container>
      <!-- 左侧树结构 -->
      <el-collapse-transition>
        <el-aside v-show="show">
          <div class="aside-title">机构列表</div>
          <el-tree :props="defaultProps" :load="loadNode" lazy @node-click="handleNodeClick"></el-tree>
        </el-aside>
      </el-collapse-transition>
      <!-- 右侧内容区 -->
      <el-main>
        <button @click="show=!show">收缩</button>
        <el-card class="box-card father-card" shadow="never">
          <!-- 头部区域 -->
          <el-row class="btns">
            <el-form ref="form" :model="form" inline>
              <!-- 搜索框 -->
              <el-form-item label="车辆名称">
                <el-input v-model="form.carNum" placeholder="请输入车牌号码" clearable></el-input>
              </el-form-item>
              <!-- 按钮区域 -->
              <el-form-item>
                <el-button type="primary" icon="el-icon-search" @click="search()">搜索</el-button>
              </el-form-item>
            </el-form>
          </el-row>
          <!-- 表格区域 -->
          <el-row class="tab">
            <el-table
              ref="multipleTable"
              :data="tabData"
              tooltip-effect="dark"
              style="width: 100%"
              highlight-current-row
              stripe
              border
              :header-cell-style="rowClass"
            >
              <el-table-column prop="corpName" label="机构名称" align="center"></el-table-column>
              <el-table-column prop="carNum" label="车牌号码" align="center"></el-table-column>
              <el-table-column prop="status" label="车辆状态" align="center">
                <template slot-scope="scope">
                  <el-tag effect="dark" v-if="scope.row.status===0">{{ '新增' }}</el-tag>
                  <el-tag type="success" effect="dark" v-else-if="scope.row.status===1">{{ '启用' }}</el-tag>
                  <el-tag type="danger" effect="dark" v-else-if="scope.row.status===2">{{ '停用' }}</el-tag>
                </template>
              </el-table-column>

              <el-table-column label="操作" align="center" min-width="200px">
                <template slot-scope="scope">
                  <el-button
                    size="mini"
                    type="primary"
                    icon="el-icon-edit"
                    @click="disAssociate(scope.row)"
                  >解除关联</el-button>
                </template>
              </el-table-column>
            </el-table>
            <!-- 分页区域 -->
            <el-pagination
              background
              @size-change="handleSizeChange"
              @current-change="handlePageNumChange"
              :current-page="paging.PageNo"
              :page-sizes="[10,15, 20, 50]"
              :page-size="paging.pageSize"
              layout="total, sizes, prev, pager, next, jumper"
              :total="total"
            ></el-pagination>
          </el-row>
        </el-card>
      </el-main>
    </el-container>
  </div>
</template>

<script>
import _ from "lodash";
export default {
  name: "orStaffManagement",
  components: {},
  data() {
    let that = this;
    return {
      treeData: [], //树结构数据源
      defaultProps: {
        label: "name",
        children: [],
        isLeaf: "leaf"
      }, //树结构配置对象
      tabData: [], //表格数据源
      show: true, //控制侧边栏的显示与隐藏
      form: { companyName: "" }, //搜索表单
      total: 0, // 总数据条数
      corpId: "",
      paging: {
        pageSize: 10, // 每页显示数据条数
        PageNo: 1 // 当前页数
      }, // 分页表单
      areaId: "", //当前选择的地区ID
      orderDialogFormVisible: false, //控制详情对话框的显示与隐藏
      addDialogFormVisible: false, //控制添加对话框的显示与隐藏
      selectForm: {}, //选中的表单对象
      // is: true,
      options: [], //经营业户行政区
      props: {
        label: "province",
        value: "uuid",
        children: "hasChild"
      },
      propst: {
        multiple: true,
        label: "province",
        value: "uuid",
        children: "children"
      },
      dialogImageUrl: "",
      dialogVisible: false,
      disabled: false,
      fileList: [],
      isDisabled: true // 控制对话框的编辑
    };
  },
  created() {
    // this.getProvince()
  },
  methods: {
    // 点击左侧树节点触发
    async handleNodeClick(data, node) {
      console.log(node.level);
      if (node.level == 4) {
        this.getSimpledVehicleList(node);
      }
    },
    // 获取省份--调试完成
    loadNode(node, resolve) {
      //如果展开第一级节点，从后台加载一级节点列表
      if (node.level == 0) {
        this.getProvince(resolve);
      }
      //如果展开其他级节点，动态从后台加载下一级节点列表
      if (node.level == 1) {
        this.getCity(node, resolve);
      }
      if (node.level == 2) {
        this.getDistrict(node, resolve);
      }
      if (node.level == 3) {
        this.getorderCompany(node, resolve);
      }
      if (node.level == 4) {
        this.getVehicleList(node, resolve);
      }
    },

    async getProvince(resolve) {
      const { data: res } = await this.$http.post(
        "/userManageServer/area/selectProvinceList",
        {}
      );
      // 判断请求是否成功
      if (res.statusCode !== "200") return this.$message.error("获取省份失败");

      res.data.forEach(item => {
        item.name = item.province;
        if (res.data.count == 0) {
          item.hasChild = false;
        } else {
          item.hasChild = true;
        }
      });
      resolve(res.data);
    },
    // 获取市--调试完成
    async getCity(node, resolve) {
      const { data: res } = await this.$http.post(
        "/userManageServer/area/selectCityList",
        {
          province: node.data.uuid
        }
      );
      // 判断请求是否成功
      if (res.statusCode !== "200") return this.$message.error("获取市失败");
      //  console.log(res.data);
      res.data.forEach(item => {
        item.name = item.cityName;
        if (res.data.count == 0) {
          item.hasChild = false;
        } else {
          item.hasChild = true;
        }
      });
      resolve(res.data);
    },
    // 获取区域--调试完成
    async getDistrict(node, resolve) {
      const { data: res } = await this.$http.post(
        "/userManageServer/area/selectDistrictList",
        {
          cityId: node.data.uuid
        }
      );
      // console.log(res.data);
      // 判断请求是否成功
      if (res.statusCode !== "200") return this.$message.error("获取区域失败");

      res.data.forEach(item => {
        item.name = item.disName;
        if (res.data.count == 0) {
          item.hasChild = false;
        } else {
          item.hasChild = true;
        }
      });
      resolve(res.data);
    },

    // 按地区获取机构--调试完成
    async getorderCompany(node, resolve) {
      const { data: res } = await this.$http.post(
        "/userManageServer/tSysCompany/selectCompany",
        {
          district: node.data.uuid
        }
      );
      // console.log(res.data)
      // 判断请求是否成功
      if (res.statusCode !== "200") return this.$message.error("获取机构失败");

      res.data.forEach(item => {
        if (res.data.count == 0) {
          item.hasChild = false;
        } else {
          item.hasChild = true;
        }
        item.name = item.companyName;
      });

      resolve(res.data);
    },
    //按机构获取车辆信息
    async getVehicleList(node, resolve) {
      // console.log(node.data.uuid)
      this.corpId = node.data.uuid;
      const { data: res } = await this.$http.post(
        "/pmService/tInfoVehicle/getTInfoVehicleList",
        {
          pageSize: this.paging.pageSize,
          pageNo: this.paging.PageNo,
          corpId: node.data.uuid
        }
      );
      if (res.statusCode !== "200")
        return this.$message.error("获取车辆信息失败");
      //  console.log(res.data);
      this.tabData = res.data;
      this.total = +res.pageBean.pageDataCount;
      resolve([]);
    },
    async getSimpledVehicleList(node) {
      // console.log(node.data.uuid)
      this.corpId = node.data.uuid;
      const { data: res } = await this.$http.post(
        "/pmService/tInfoVehicle/getTInfoVehicleList",
        {
          pageSize: this.paging.pageSize,
          pageNo: this.paging.PageNo,
          corpId: node.data.uuid
        }
      );
      if (res.statusCode !== "200")
        return this.$message.error("获取车辆信息失败");
      //  console.log(res.data);
      this.tabData = res.data;
      this.total = +res.pageBean.pageDataCount;
    },
    //解除关联操作
    async disAssociate(v) {
      this.$confirm("此操作将解除与机构关联, 是否继续?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning"
      })
        .then(() => {
          this.operationCancel(v)
          this.$message({
            type: 'success',
            message: '解除成功!'
          });
        })
        .catch(() => {
          this.$message({
            type: "info",
            message: "已取消解除关联"
          });
        });
    },
    //执行解除关联
    async operationCancel(v) {
      console.log(v.uuid, this.corpId);
      const { data: res } = await this.$http.post(
        "/pmService/tInfoVehicle/deleteTInfoVehicleCorp",
        {
          uuid: v.uuid
        }
      );
      console.log(res);


      const { data: result } = await this.$http.post(
        "/pmService/tInfoVehicle/getTInfoVehicleList",
        {
          pageSize: this.paging.pageSize,
          pageNo: this.paging.PageNo,
          corpId: this.corpId
        }
      );
      console.log(result.data);
      this.tabData = result.data;
      this.total = +result.pageBean.pageDataCount;
    }
    ,
    // 给表格头部设定背景色
    rowClass({ row, rowIndex }) {
      return "background:#e4eaec";
    },
    // 切换选中表格项时触发
    handleCurrentChange(val) {
      if (!val) return;
      // console.log(val);
      // this.Selected = true
      // this.editForm = val
      // this.allotForm = val
    },
    // 点击搜索按钮触发
    async search() {
      // 判断有输入账号
      if (this.form.carNum === "") return this.$message.error("请输入车辆名称");
      // console.log(this.areaId, this.form.companyName);
      // 调接口，进行查询
      const { data: res } = await this.$http.post(
        "/pmService/tInfoVehicle/getTInfoVehicleList",
        {
          pageSize: this.paging.pageSize,
          pageNo: this.paging.PageNo,
          carNum: this.form.carNum
        }
      );
      console.log(res);
      // 判断请求是否成功
      if (res.statusCode !== "200") return this.$message.error("查询失败");
      this.tabData = res.data;
      this.total = +res.pageBean.pageDataCount;
    },
    // 切换每页显示数量时触发
    async handleSizeChange(newSize) {
      this.paging.pageSize = newSize;
      // await this.getUserList()
    },
    // 切换页码时触发
    async handlePageNumChange(PageNum) {
      this.paging.PageNo = PageNum;
      // await this.getUserList()
    },




  }
};
</script>
<style lang="less" scoped>
.orStaffManagement {
  width: 100%;
  height: 100%;
  //隐藏滚动条
  ::-webkit-scrollbar {
    // display: none; /* Chrome Safari */
  }
  .el-container {
    width: 100%;
    height: 100%;
    /deep/ .el-aside {
      border: 1px solid #ccc;
      .aside-title {
        position: relative;
        font-size: 18px;
        padding-left: 10px;
        background-color: #e9f0fe;
        margin-bottom: 5px;
      }
    }
    .el-main {
      padding: 0;
      .father-card {
        .btns {
          margin-bottom: 10px;
        }
        .tab {
          /deep/ .el-table {
            margin-bottom: 20px;
            .el-table__row--striped td {
              background-color: #e4eaec !important;
            }
            .current-row > td {
              background-color: #66b1ff !important;
            }
          }
          .el-pagination {
            margin-bottom: 0.25rem;
            margin-right: 0.25rem;
            text-align: right;
          }
        }
        .add-dialog,
        .order-dialog {
          .dialog-Title {
            color: #0097fe;
            font-size: 20px;
            font-weight: 700;
          }
          /deep/ .el-form {
            .subtitle {
              color: #0097fe;
              font-size: 14px;
              font-weight: 700;
              margin-bottom: 20px;
              padding-left: 6px;
              border-left: 5px solid #0097fe;
            }
            .el-row {
              display: flex;
              .el-form-item {
                flex: 1;
                display: flex;
                .el-form-item__content {
                  flex: 1;
                  .el-input {
                    width: 100%;
                    .el-input__inner {
                      width: 100%;
                    }
                  }
                  .el-select {
                    width: 100%;
                  }
                }
              }
            }
          }
        }
      }
    }
  }
}
</style>
